* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  color: #343a40;
  line-height: 1;
}

.accordion {
  width: calc(100% - 10%);
  margin: 120px auto;
  margin-top: 30px;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.item {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  padding: 10px 14px;
  padding-right: 48px;
  cursor: pointer;
  border-top: 4px solid #fff;
  border-bottom: 4px solid #fff;

  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 24px;
  row-gap: 32px;
  align-items: center;
}

.number {
  font-size: 22px;
  font-weight: 500;
  color: #ced4da;
}

.title {
  font-size: 16px;
  font-weight: 500;
}

.icon {
  font-size: 22px;
  font-weight: 500;
}

.content-box {
  grid-column: 2 / -1;
  padding-bottom: 16px;
  line-height: 1.6;
}

.content-box ul {
  color: #868e96;
  margin-left: 16px;
  margin-top: 16px;

  display: flex;
  flex-direction: column;
  gap: 12px;
}

/* OPEN STATE */
.open {
  border-top: 4px solid #1a276e;
}

.open .number,
.open .title {
  color: #1a276e;
}

.textarea {
  width: calc(100% - 5%);
  overflow: auto;
}

.menubar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  border-bottom: 1px solid #1a276e;
  justify-content: space-between;
  display: flex;
}

.handleButtons {
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 10px;
  padding-right: 80px;
}

.linux-buttons {
  display: flex;
  gap: 10px;
}

.button {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  font-size: 12px;
  font-family: sans-serif;
  font-weight: bold;
  letter-spacing: 2px;
  color: white;
  background: #1a276e;
  border: none;
  width: 170px;
  height: 46px;
  cursor: pointer;
  border-radius: 25px;
  transition: transform 0.3s ease-in-out;
}

.button:hover {
  background: #5643fd;
  transform: scale(1.1);
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(270deg, white, white, #5643fd, #1a276e);
  padding: 10px;
  text-align: center;
}

.message-no-item {
  display: flex;
  justify-content: center;
  margin-top: 25vh;
  font-size: 20px;
  font-weight: 500;
}

.note {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.button-submit {
  font-size: 12px;
  font-family: sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: white;
  background: #1a276e;
  border: none;
  width: 140px;
  height: 32px;
  cursor: pointer;
  border-radius: 10px;
  margin-top: 5px;
}

.button-submit:hover {
  background: #5643fd;
}

input[type="checkbox"] {
  width: 22px;
  height: 22px;
  margin-right: 2px;
  cursor: pointer;
  accent-color: #5643fd;
}

.acc-buttons {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.edit-button {
  cursor: pointer;
  height: 22px;
  margin-right: 2px;
}

.edit-button:hover {
  background-color: rgb(86, 67, 253);
}

.markedQ {
  margin-top: 100px;
}

.stats {
  margin-top: 180px;
  margin-left: 40%;
  font-size: 22px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 8px;
  text-transform: uppercase;
}

.card-content {
  border-radius: 6px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  margin-top: 22px;
  margin-left: 40px;
  width: 360px;
  height: 110px;
  background-color: rgb(240, 240, 240);

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.card-content-element {
  border-radius: 6px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  margin-top: 22px;
  margin-left: 40px;
  width: 760px;
  height: 110px;
  background-color: rgb(240, 240, 240);

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.left {
  font-size: 48px;
}

.center-stats {
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 120px;
}

.stats-total {
  display: flex;
  justify-content: space-between;
}

.left-img {
  height: 48px;
  margin-left: 16px;
}

.left-flex {
  display: flex;
  gap: 12px;
  align-items: center;
}

.stat-text {
  color: #1a276e;
  font-size: 42px;
}

.statslogo {
  height: 82px;
  margin-bottom: 8px;
}

.stats-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 22px;
}

.btn-container-secondary {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 28px;
}

.topmain {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}

.new-question-form {
  /* background-color: #394272; */
  width: calc(100% - 10%);
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin-left: 80px;
  /* align-items: center; */
  gap: 12px;
  /* width: 90vw; */
  border: 2px solid #1a276e;
  border-top: 6px solid #1a276e;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}

.new-question-text {
  color: #1a276e;
  font-size: 20px;
  font-weight: 500;
}

.attachment {
  display: flex;
  align-items: center;
  gap: 12px;
}

.btn-add-question {
  margin-top: 12px;
  font-size: 12px;
  font-family: sans-serif;
  font-weight: bold;
  letter-spacing: 2px;
  color: white;
  background: #1a276e;
  border: none;
  width: 230px;
  height: 46px;
  cursor: pointer;
  border-radius: 25px;
}

.btn-add-question:hover {
  background: #5643fd;
}

.btn-attach {
  font-size: 12px;
  font-family: sans-serif;
  font-weight: bold;
  letter-spacing: 2px;
  color: white;
  background: #1a276e;
  border: none;
  width: 180px;
  height: 38px;
  cursor: pointer;
  border-radius: 25px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  padding: 2px;
}

.btn-attach:hover {
  background: #5643fd;
}

.delete-button:hover {
  background: #5643fd;
  cursor: pointer;
}

.button-s {
  font-size: 12px;
  font-family: sans-serif;
  font-weight: bold;
  letter-spacing: 1px;
  color: white;
  background: #1a276e;
  border: none;
  width: 120px;
  height: 46px;
  cursor: pointer;
  border-radius: 25px;
}

.button-s:hover {
  background: #5643fd;
}

.container-btns-marked {
  display: flex;
  justify-content: center;
}

.category-array {
  font-style: italic;
  margin-left: 1px;
}

.header-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.btn-svg {
  height: 22px;
}

.category-text {
  font-size: 32px;
}
.category-name {
  font-weight: 700;
  color: #1a276e;
}

.main-startscreen {
  /* height: calc(100vh - 122px); */
  padding: 30px;
  /* display: flex; */
  /* align-items: center;
  justify-content: center; */
}

.main-welcometext {
  font-size: 26px;
  color: #1a276e;
  font-style: italic;
  display: flex;
  justify-content: center;
  margin-bottom: 56px;
}

.back-to-home-btn {
  margin-top: 30px;
  margin-left: 5vw;
}

/* test */

.main-top {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 48px;
}
.main-main {
  display: flex;
}
.home-img-left {
  height: 138px;
}
.btn-main-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  justify-content: center; /* Centers the grid horizontally */
  gap: 28px; /* Adds gaps between grid cells */
  margin-bottom: 48px;
}

.new-category-btn-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.new-category-input {
  padding: 10px; /* Add padding to make input area more spacious */
  font-size: 16px; /* Adjust font size */
  border: 2px solid #ccc; /* Add border for visual distinction */
  border-radius: 5px; /* Add rounded corners */
  width: 300px; /* Adjust width as needed */
}

.new-category-input:focus {
  outline: none; /* Remove default focus outline */
  border-color: #1a276e; /* Change border color on focus */
}

/* Style placeholder text */
.new-category-input::placeholder {
  color: #999; /* Set placeholder text color */
}

/* Style value text */
.new-category-input[value] {
  color: #333; /* Set text color */
}

.new-category-text {
  color: #1a276e;
  font-size: 18px;
  font-weight: 700;
}

.button-menu {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  font-size: 12px;
  font-family: sans-serif;
  font-weight: bold;
  letter-spacing: 2px;
  color: white;
  background: #1a276e;
  border: none;
  width: 230px;
  height: 74px;
  cursor: pointer;
  border-radius: 25px;
  transition: transform 0.3s ease-in-out;
}

.button-menu:hover {
  background: #5643fd;
  transform: scale(1.1);
}

.button-menu-marked {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  font-size: 12px;
  font-family: sans-serif;
  font-weight: bold;
  letter-spacing: 2px;
  color: #1a276e;
  background: white;
  border: 2px solid #1a276e;
  width: 230px;
  height: 74px;
  cursor: pointer;
  border-radius: 25px;
  transition: transform 0.3s ease-in-out;
}

.button-menu-marked:hover {
  /* background: #5643fd; */
  /* color: white; */
  transform: scale(1.1);
  /* border: none; */
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    background-image: white;
  }

  .main-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin-bottom: 48px;
  }

  .home-img-left {
    height: 110px;
  }

  .btn-main-menu {
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 12px;
  }

  .footer {
    display: none;
  }

  .back-to-home-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 18px;
    margin-left: 0;
  }

  .btn-container-secondary {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  /* test */

  .accordion {
    width: 95%;
    margin-top: 24px;
    margin-left: 0;
    margin-right: 0;
  }
  .item {
    margin-left: 12px;
    padding: 0;
  }

  .title {
    font-size: 12px;
    overflow: hidden;
  }

  .icon {
    display: none;
  }
  .acc-buttons {
    margin-left: 12px;
  }

  .hide {
    display: none;
  }

  .note {
    margin-left: 6px;
    max-width: 90vw;
  }

  .new-question-form {
    margin: 0;
  }

  .button-menu-marked {
    width: 85vw;
  }

  .button-menu {
    width: 85vw;
  }

  .textarea {
    wrap: off;
  }
}
