.errorLabel {
  color: red;
  margin-top: 8px;
  font-size: 12px;
}

.center-page {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30vh;
}

.login-text {
  font-family: monospace;
  font-size: 32px;
}

.button {
  font-size: 12px;
  font-family: sans-serif;
  font-weight: bold;
  letter-spacing: 2px;
  color: white;
  background: #1a276e;
  border: none;
  width: 170px;
  height: 46px;
  cursor: pointer;
  border-radius: 25px;
}

.button:hover {
  background: #5643fd;
}
