.navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* box-shadow: 0 0 30px rgba(0, 0, 0, 0.1); */
  height: 122px;

  background: linear-gradient(90deg, white, white, #5643fd, #1a276e);
}

.logo {
  height: 120px;
}

.userbox {
  width: 365px;
  height: 92px;
  border-radius: 8px;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  background-color: white;
  border: 1px solid #1a276e;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  padding: 18px;
}

.userbox:last-child {
  /* Different styling for the last child */
  position: relative;
  /* top: -20px; */
}

.userbox-avatar {
  height: 68px;
  border-radius: 4px;
  margin-right: 12px;
}

.userbox-details {
  display: flex;
  flex-direction: column;
  height: 68px;
  justify-content: space-between;
  color: #1a276e;
  cursor: default;
}

/* .userbox-welcome {
} */

.userbox-username {
  font-style: italic;
  font-weight: 600;
}

.userbox-btn {
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 2px;
  color: white;
  background: #1a276e;
  border: none;
  width: 100px;
  height: 22px;
  cursor: pointer;
  border-radius: 25px;
  transition: transform 0.3s ease-in-out;
}

.userbox-btn:hover {
  background: #5643fd;
  color: white;
  transform: scale(1.1);
}

.logout-logo {
  height: 12px;
}

.btn-svg {
  height: 16px;
}

.home-logo {
  height: 22px;
  position: relative;
  left: 26px;
  top: -22px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.home-logo:hover {
  transform: scale(1.2);
}

.home-text {
  position: relative;
  font-size: 8px;
  right: -4px;
  top: -4px;
}

@media (max-width: 768px) {
  .navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    /* box-shadow: 0 0 30px rgba(0, 0, 0, 0.1); */
    height: 210px;

    background: none;
  }
}
