/* WELCOME TEXT EFFECT */
.wrapper {
  margin-top: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.css-typing p {
  border-right: 0.15em solid #1a276e;
  white-space: nowrap;
  overflow: hidden;
  font-family: monospace;
  align-items: center;
}
.css-typing p:nth-child(1) {
  margin-top: 100px;
  width: 38ch;
  font-size: 3em;
  -webkit-animation: type 2s steps(23, end);
  animation: type 2s steps(23, end);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@keyframes type {
  0% {
    width: 0;
  }
  99.9% {
    border-right: 0.15em solid #1a276e;
  }
  100% {
    border: none;
  }
}

@-webkit-keyframes type {
  0% {
    width: 0;
  }
  99.9% {
    border-right: 0.15em solid orange;
  }
  100% {
    border: none;
  }
}

@keyframes type2 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes type2 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}
@-webkit-keyframes blink {
  50% {
    border-color: tranparent;
  }
}
/*  */

.button {
  font-size: 12px;
  font-family: sans-serif;
  font-weight: bold;
  letter-spacing: 2px;
  color: white;
  background: #1a276e;
  border: none;
  width: 170px;
  height: 46px;
  cursor: pointer;
  border-radius: 25px;
}

.button:hover {
  background: #5643fd;
}
